import React, { Component } from 'react';
import Select from 'react-select';
import './WeeklyData.css';

const options = [
    { value: 1, label: 'Week 1' },
    { value: 2, label: 'Week 2' },
    { value: 3, label: 'Week 3' },
    { value: 4, label: 'Week 4' },
    { value: 5, label: 'Week 5' },
    { value: 6, label: 'Week 6' },
    { value: 7, label: 'Week 7' },
    { value: 8, label: 'Week 8' },
    { value: 9, label: 'Week 9' },
    { value: 10, label: 'Week 10' },
    { value: 11, label: 'Week 11' },
    { value: 12, label: 'Week 12' },
    { value: 13, label: 'Week 13' },
    { value: 14, label: 'Week 14' },
    { value: 15, label: 'Week 15' },
    { value: 16, label: 'Week 16' },
    { value: 17, label: 'Week 17' },
    { value: 18, label: 'Week 18' },
    { value: 19, label: 'Wild Card' },
    { value: 20, label: 'Divisional' },
    { value: 21, label: 'Championship' },
    { value: 23, label: 'Super Bowl' }];

export class WeeklyData extends Component {
    static displayName = WeeklyData.name;

    constructor(props) {
        super(props);
        this.state = {
            weeklyPicks: [],
            loading: true,
            weekNo: 1,
            sort: {
                column: 'weeklyPoints',
                direction: 'desc',
            }
        };
        this.onSort = this.onSort.bind(this)
    }

    async componentDidMount() {
        await this.populateCurrentWeek();
        await this.populateAbbreviations();
        this.populateWeeklyPickData();
    }

    onSort = (column) => (e) => {
        let direction = 'desc';

        if (this.state.sort.column && column === this.state.sort.column) {
            direction = this.state.sort.column ? (this.state.sort.direction === 'asc' ? 'desc' : 'asc') : 'desc';
        }
        else if (column === 'name') {
            direction = 'asc';
        }
        else {
            direction = 'desc';
        }
        const sortedData = this.state.weeklyPicks.sort((a, b) => {
            if (column !== 'name' && column !== 'weeklyPoints' && column !== 'possiblePoints' && column !== 'mnfScore') {
                const nameA = a[column]['winner'].toUpperCase(); // ignore upper and lowercase
                const nameB = b[column]['winner'].toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                if (nameA === nameB) {
                    const pointA = a[column]['confidencePoints']; // ignore upper and lowercase
                    const pointB = b[column]['confidencePoints']; // ignore upper and lowercase
                    if (pointA < pointB) {
                        return -1;
                    }
                    if (pointA > pointB) {
                        return 1;
                    }
                }

                // names must be equal
                return 0;
            }
            else if (column === 'weeklyPoints' || column === 'possiblePoints') {
                const nameA = a['user'][column]; // ignore upper and lowercase
                const nameB = b['user'][column]; // ignore upper and lowercase
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }

                // names must be equal
                return 0;
            }
            else if (column === 'mnfScore') {
                const nameA = a[column]; // ignore upper and lowercase
                const nameB = b[column]; // ignore upper and lowercase
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }

                // names must be equal
                return 0;
            }
            else if (column === 'name') {
                const nameA = a['user'][column].toUpperCase(); // ignore upper and lowercase
                const nameB = b['user'][column].toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }

                // names must be equal
                return 0;
            }
            else {
                return a.contractValue - b.contractValue;
            }
        });

        if (direction === 'desc') {
            sortedData.reverse();
        }

        this.setState({
            weeklyPicks: sortedData,
            sort: {
                column,
                direction,
            }
        });
    };

    renderWeeklyPicksTable(weeklyPicks) {        
        return (
            <div id="weeklyTableDiv">
                <table className='table table-striped' aria-labelledby="tabelLabel" id="weeklyTable">
                    <thead>
                        <tr style={{ cursor: 'pointer' }}>
                            <th style={{ fontWeight: this.state.sort.column === 'name' ? 'bold' : '' }} onClick={this.onSort('name')}>Name</th>
                            <th style={{ fontWeight: this.state.sort.column === 'weeklyPoints' ? 'bold' : '' }} onClick={this.onSort('weeklyPoints')}>Total</th>
                            <th style={{ fontWeight: this.state.sort.column === 'possiblePoints' ? 'bold' : '' }} onClick={this.onSort('possiblePoints')}>Possible</th>
                            <th style={{ fontWeight: this.state.sort.column === 'game1' ? 'bold' : '' }} onClick={this.onSort('game1')}>{this.state.abbreviations[0]}</th>
                            <th style={{ fontWeight: this.state.sort.column === 'game2' ? 'bold' : '' }} onClick={this.onSort('game2')}>{this.state.abbreviations[1]}</th>
                            <th style={{ fontWeight: this.state.sort.column === 'game3' ? 'bold' : '' }} onClick={this.onSort('game3')}>{this.state.abbreviations[2]}</th>
                            <th style={{ fontWeight: this.state.sort.column === 'game4' ? 'bold' : '' }} onClick={this.onSort('game4')}>{this.state.abbreviations[3]}</th>
                            <th style={{ fontWeight: this.state.sort.column === 'game5' ? 'bold' : '' }} onClick={this.onSort('game5')}>{this.state.abbreviations[4]}</th>
                            <th style={{ fontWeight: this.state.sort.column === 'game6' ? 'bold' : '' }} onClick={this.onSort('game6')}>{this.state.abbreviations[5]}</th>
                            <th style={{ fontWeight: this.state.sort.column === 'game7' ? 'bold' : '' }} onClick={this.onSort('game7')}>{this.state.abbreviations[6]}</th>
                            <th style={{ fontWeight: this.state.sort.column === 'game8' ? 'bold' : '' }} onClick={this.onSort('game8')}>{this.state.abbreviations[7]}</th>
                            <th style={{ fontWeight: this.state.sort.column === 'game9' ? 'bold' : '' }} onClick={this.onSort('game9')}>{this.state.abbreviations[8]}</th>
                            <th style={{ fontWeight: this.state.sort.column === 'game10' ? 'bold' : '' }} onClick={this.onSort('game10')}>{this.state.abbreviations[9]}</th>
                            <th style={{ fontWeight: this.state.sort.column === 'game11' ? 'bold' : '' }} onClick={this.onSort('game11')}>{this.state.abbreviations[10]}</th>
                            <th style={{ fontWeight: this.state.sort.column === 'game12' ? 'bold' : '' }} onClick={this.onSort('game12')}>{this.state.abbreviations[11]}</th>
                            <th style={{ fontWeight: this.state.sort.column === 'game13' ? 'bold' : '' }} onClick={this.onSort('game13')}>{this.state.abbreviations[12]}</th>
                            <th style={{ fontWeight: this.state.sort.column === 'game14' ? 'bold' : '' }} onClick={this.onSort('game14')}>{this.state.abbreviations[13]}</th>
                            <th style={{ fontWeight: this.state.sort.column === 'game15' ? 'bold' : '' }} onClick={this.onSort('game15')}>{this.state.abbreviations[14]}</th>
                            <th style={{ fontWeight: this.state.sort.column === 'game16' ? 'bold' : '' }} onClick={this.onSort('game16')}>{this.state.abbreviations[15]}</th>
                            <th style={{ fontWeight: this.state.sort.column === 'mnfScore' ? 'bold' : '' }} onClick={this.onSort('mnfScore')}>MNF</th>
                        </tr>
                    </thead>
                    <tbody>
                        {weeklyPicks.map(weeklyPick =>
                            <tr key={weeklyPick.user.userid}>
                                <td style={{ textAlign: 'left', padding: '5px', fontWeight: this.state.sort.column === 'name' ? 'bold' : '' }}>{weeklyPick.user.name}</td>
                                <td style={{ fontWeight: this.state.sort.column === 'weeklyPoints' ? 'bold' : ''  }}>{weeklyPick.user.weeklyPoints}</td>
                                <td style={{ fontWeight: this.state.sort.column === 'possiblePoints' ? 'bold' : ''  }}>{weeklyPick.user.possiblePoints}</td>
                                <td style={{ color: (weeklyPick.game1.isGameOver ? (weeklyPick.game1 != null && weeklyPick.game1.isCorrect ? 'green' : 'red') : 'black'), textDecorationLine: (weeklyPick.game1.isGameOver ? (weeklyPick.game1 != null && weeklyPick.game1.isCorrect ? '' : 'line-through') : ''), fontWeight: this.state.sort.column === 'game1' ? 'bold' : ''  }}>{weeklyPick.game1.gameAndPoints}</td>
                                <td style={{ color: (weeklyPick.game2.isGameOver ? (weeklyPick.game2 != null && weeklyPick.game2.isCorrect ? 'green' : 'red') : 'black'), textDecorationLine: (weeklyPick.game2.isGameOver ? (weeklyPick.game2 != null && weeklyPick.game2.isCorrect ? '' : 'line-through') : ''), fontWeight: this.state.sort.column === 'game2' ? 'bold' : '' }}>{this.state.abbreviations.length > 1 ? weeklyPick.game2.gameAndPoints : null}</td>
                                <td style={{ color: (weeklyPick.game3.isGameOver ? (weeklyPick.game3 != null && weeklyPick.game3.isCorrect ? 'green' : 'red') : 'black'), textDecorationLine: (weeklyPick.game3.isGameOver ? (weeklyPick.game3 != null && weeklyPick.game3.isCorrect ? '' : 'line-through') : ''), fontWeight: this.state.sort.column === 'game3' ? 'bold' : '' }}>{this.state.abbreviations.length > 2 ? weeklyPick.game3.gameAndPoints : null}</td>
                                <td style={{ color: (weeklyPick.game4.isGameOver ? (weeklyPick.game4 != null && weeklyPick.game4.isCorrect ? 'green' : 'red') : 'black'), textDecorationLine: (weeklyPick.game4.isGameOver ? (weeklyPick.game4 != null && weeklyPick.game4.isCorrect ? '' : 'line-through') : ''), fontWeight: this.state.sort.column === 'game4' ? 'bold' : '' }}>{this.state.abbreviations.length > 3 ? weeklyPick.game4.gameAndPoints : null}</td>
                                <td style={{ color: (weeklyPick.game5.isGameOver ? (weeklyPick.game5 != null && weeklyPick.game5.isCorrect ? 'green' : 'red') : 'black'), textDecorationLine: (weeklyPick.game5.isGameOver ? (weeklyPick.game5 != null && weeklyPick.game5.isCorrect ? '' : 'line-through') : ''), fontWeight: this.state.sort.column === 'game5' ? 'bold' : '' }}>{this.state.abbreviations.length > 4 ? weeklyPick.game5.gameAndPoints : null}</td>
                                <td style={{ color: (weeklyPick.game6.isGameOver ? (weeklyPick.game6 != null && weeklyPick.game6.isCorrect ? 'green' : 'red') : 'black'), textDecorationLine: (weeklyPick.game6.isGameOver ? (weeklyPick.game6 != null && weeklyPick.game6.isCorrect ? '' : 'line-through') : ''), fontWeight: this.state.sort.column === 'game6' ? 'bold' : '' }}>{this.state.abbreviations.length > 5 ? weeklyPick.game6.gameAndPoints : null}</td>
                                <td style={{ color: (weeklyPick.game7.isGameOver ? (weeklyPick.game7 != null && weeklyPick.game7.isCorrect ? 'green' : 'red') : 'black'), textDecorationLine: (weeklyPick.game7.isGameOver ? (weeklyPick.game7 != null && weeklyPick.game7.isCorrect ? '' : 'line-through') : ''), fontWeight: this.state.sort.column === 'game7' ? 'bold' : '' }}>{this.state.abbreviations.length > 6 ? weeklyPick.game7.gameAndPoints : null}</td>
                                <td style={{ color: (weeklyPick.game8.isGameOver ? (weeklyPick.game8 != null && weeklyPick.game8.isCorrect ? 'green' : 'red') : 'black'), textDecorationLine: (weeklyPick.game8.isGameOver ? (weeklyPick.game8 != null && weeklyPick.game8.isCorrect ? '' : 'line-through') : ''), fontWeight: this.state.sort.column === 'game8' ? 'bold' : '' }}>{this.state.abbreviations.length > 7 ? weeklyPick.game8.gameAndPoints : null}</td>
                                <td style={{ color: (weeklyPick.game9.isGameOver ? (weeklyPick.game9 != null && weeklyPick.game9.isCorrect ? 'green' : 'red') : 'black'), textDecorationLine: (weeklyPick.game9.isGameOver ? (weeklyPick.game9 != null && weeklyPick.game9.isCorrect ? '' : 'line-through') : ''), fontWeight: this.state.sort.column === 'game9' ? 'bold' : '' }}>{this.state.abbreviations.length > 8 ? weeklyPick.game9.gameAndPoints : null}</td>
                                <td style={{ color: (weeklyPick.game10.isGameOver ? (weeklyPick.game10 != null && weeklyPick.game10.isCorrect ? 'green' : 'red') : 'black'), textDecorationLine: (weeklyPick.game10.isGameOver ? (weeklyPick.game10 != null && weeklyPick.game10.isCorrect ? '' : 'line-through') : ''), fontWeight: this.state.sort.column === 'game10' ? 'bold' : '' }}>{this.state.abbreviations.length > 9 ? weeklyPick.game10.gameAndPoints : null}</td>
                                <td style={{ color: (weeklyPick.game11.isGameOver ? (weeklyPick.game11 != null && weeklyPick.game11.isCorrect ? 'green' : 'red') : 'black'), textDecorationLine: (weeklyPick.game11.isGameOver ? (weeklyPick.game11 != null && weeklyPick.game11.isCorrect ? '' : 'line-through') : ''), fontWeight: this.state.sort.column === 'game11' ? 'bold' : '' }}>{this.state.abbreviations.length > 10 ? weeklyPick.game11.gameAndPoints : null}</td>
                                <td style={{ color: (weeklyPick.game12.isGameOver ? (weeklyPick.game12 != null && weeklyPick.game12.isCorrect ? 'green' : 'red') : 'black'), textDecorationLine: (weeklyPick.game12.isGameOver ? (weeklyPick.game12 != null && weeklyPick.game12.isCorrect ? '' : 'line-through') : ''), fontWeight: this.state.sort.column === 'game12' ? 'bold' : '' }}>{this.state.abbreviations.length > 11 ? weeklyPick.game12.gameAndPoints : null}</td>
                                <td style={{ color: (weeklyPick.game13.isGameOver ? (weeklyPick.game13 != null && weeklyPick.game13.isCorrect ? 'green' : 'red') : 'black'), textDecorationLine: (weeklyPick.game13.isGameOver ? (weeklyPick.game13 != null && weeklyPick.game13.isCorrect ? '' : 'line-through') : ''), fontWeight: this.state.sort.column === 'game13' ? 'bold' : '' }}>{this.state.abbreviations.length > 12 ? weeklyPick.game13.gameAndPoints : null}</td>
                                <td style={{ color: (weeklyPick.game14.isGameOver ? (weeklyPick.game14 != null && weeklyPick.game14.isCorrect ? 'green' : 'red') : 'black'), textDecorationLine: (weeklyPick.game14.isGameOver ? (weeklyPick.game14 != null && weeklyPick.game14.isCorrect ? '' : 'line-through') : ''), fontWeight: this.state.sort.column === 'game14' ? 'bold' : '' }}>{this.state.abbreviations.length > 13 ? weeklyPick.game14.gameAndPoints : null}</td>
                                <td style={{ color: (weeklyPick.game15.isGameOver ? (weeklyPick.game15 != null && weeklyPick.game15.isCorrect ? 'green' : 'red') : 'black'), textDecorationLine: (weeklyPick.game15.isGameOver ? (weeklyPick.game15 != null && weeklyPick.game15.isCorrect ? '' : 'line-through') : ''), fontWeight: this.state.sort.column === 'game15' ? 'bold' : '' }}>{this.state.abbreviations.length > 14 ? weeklyPick.game15.gameAndPoints : null}</td>
                                <td style={{ color: (weeklyPick.game16.isGameOver ? (weeklyPick.game16 != null && weeklyPick.game16.isCorrect ? 'green' : 'red') : 'black'), textDecorationLine: (weeklyPick.game16.isGameOver ? (weeklyPick.game16 != null && weeklyPick.game16.isCorrect ? '' : 'line-through') : ''), fontWeight: this.state.sort.column === 'game16' ? 'bold' : '' }}>{this.state.abbreviations.length > 15 ? weeklyPick.game16.gameAndPoints : null}</td>
                                <td style={{ color: 'black', fontWeight: this.state.sort.column === 'mnfScore' ? 'bold' : '' }}>{weeklyPick.mnfScore}</td>
                            </tr>
                        )}
                    </tbody>
                </table>

            </div>

        );
    }

    async handleChange(e) {
        await this.setState({
            weekNo: e.value
        });
        this.populateAbbreviations();
        this.populateWeeklyPickData();
        this.renderWeeklyPicksTable(this.state.weeklyPicks);
        this.render();
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderWeeklyPicksTable(this.state.weeklyPicks);

        return (
            <div className="formContainer">
                <div className="form">
                <h1 id="tabelLabel">Weekly Results</h1>
                <Select id="allWeekNo" value={{ value: this.state.weekNo, label: 'Week ' + this.state.weekNo }} onChange={this.handleChange.bind(this)} options={options} />
                {contents}
                </div>
            </div>
        );
    }

    async populateWeeklyPickData() {
        const response = await fetch('users/picks/' + this.state.weekNo);
        const data = await response.json();
        this.setState({ weeklyPicks: data, loading: false });
    }

    async populateCurrentWeek() {
        const response = await fetch('week/weekNo');
        const weekNo = await response.json();
        this.setState({ weekNo: weekNo });

        var weekPicker = document.getElementById('allWeekNo');
        weekPicker.value = { value: weekNo, label: 'Week ' + weekNo };
    }

    async populateAbbreviations() {
        const response = await fetch('week/weekGameAbbreviations/' + this.state.weekNo);
        const data = await response.json();
        this.setState({ abbreviations: data });
    }
}
